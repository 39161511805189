.right-sidebar {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  
  padding: 20px;
  box-shadow: 0 0 10px rgb(250, 250, 250);
  z-index: 999;
  width: 600px;
  height: 600px;

  
}

.donut-chart-container {
  text-align: center;
  margin-bottom: 20px;
}

.input-container {
  margin-bottom: 20px;
}

.input-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  width: 200px;
  color: #fff
}

.input-container input {
  width: 200px;;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #fff
}

.slider-container {
  margin-bottom: 20px;
  color: #fff
}

.slider-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  
}

.slider-container input[type='range'] {
  width: 100%;
}

.slider-container p {
  margin: 0;
  font-size: 12px;
  text-align: right;
}

.optimizer-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  
}

.optimizer-buttons button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 120px;;
}

.build-button-container {
  text-align: right;
}

.build-button {
  padding: 8px 16px;
  background-color: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
}


.created-card,
.fetched-card {
  
  
  padding: 20px;
  box-shadow: 0 0 10px rgb(250, 250, 250);
  z-index: 999;
  width: 300px;
  height: 400px;
}