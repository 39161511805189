/* cardDisplay.css */
.card-container {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  width: 200px;

  /* Add any other styles you want for the card container */
}

.card-container h3 {
  color: #fff;
  font-size: 1.5rem;
  width: 200px;

  /* Add any other styles you want for the card title (h3) */
}

.card-container p {
  color: #fff;
  font-size: 1rem;
  width: 200px;
  /* Add any other styles you want for the card description (p) */
}

.container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Adjust the "200px" value as needed */
  grid-gap: 10px; /* Add any gap between the cards */
  max-width: 900px; /* Set the maximum width of the grid to 700 pixels */
  margin: 50px 0 0 150px; /* Center the grid horizontally on the page */
  justify-content: flex-end;
}

.card-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(
    14,
    49,
    191,
    0.9
  ); /* Change the last value (0.9) to adjust the transparency */
  padding: 20px;
  box-shadow: 0 0 10px rgba(14, 172, 240, 0.9);
  z-index: 999;
  opacity: 0; /* Set initial opacity to 0 to enable smooth transition */
  visibility: hidden; /* Set initial visibility to hidden */
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
  background-color: rgba(
    0,
    0,
    0,
    0.82
  ); /* Add transition for smoother animation with a delay */
  width: 850px; /* Set the maximum width of the grid to 700 pixels */
  margin: 0px 0 0 -360px; /* Center the grid horizontally on the page */
  justify-content: flex-end;
}

.card-popup.open {
  opacity: 1; /* Set opacity to 1 when open */
  visibility: visible; /* Set visibility to visible when open */
  transition: opacity 0.3s ease; /* Add transition for smoother animation when closing */
}

.card-popup-content {
  margin: 0 auto;
  max-width: 900px;
}

.card-popup h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #fff;
}

.card-popup p {
  margin-bottom: 10px;
  color: #fff;
}

.card-popup button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.backgroundAnimation {
  animation: fillColor 0s forwards;
}

/* Add this CSS to your CardDisplay.css file */
@keyframes fillColor {
  from {
    background-color: transparent;
  }
  to {
    background-color: backgroundColor;
  }
}

.fillAnimation {
  animation: fillColor 2s forwards;
}
