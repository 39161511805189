/* PortfolioBuilderPopup.css */
.tab-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  background-color: hsl(0, 76%, 36%);
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.active-tab {
  background-color: #007bff;
  color: white;
}

/* Tab Content */
.tab-content {
  margin-top: 20px;

  overflow: auto;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Content container within the popup */
.popup-content {
  background-color: rgb(28, 25, 25);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 800px;
  max-height: 80vh; /* Set a maximum height for the content */
  overflow-y: auto;
  width: 90%; /* Adjust the width as needed */
  max-width: 1000px; /* Set a maximum width */
  max-height: 80vh;
  overflow-y: auto;

  /* Center the content horizontally and vertically */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Additional styling */
  margin: auto;
}

/* Individual chart container */
.chart-container {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

/* Doughnut chart specific styling */
.doughnut-chart {
  height: 250px;
}

/* Line chart specific styling */
.line-chart {
  height: 300px;
}

/* Input fields container */
.input-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Buttons container */
.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

/* Button styling */
.button-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-container button:hover {
  background-color: #0056b3;
}

/* Add padding and styling to scrollbar */
.popup-container::-webkit-scrollbar {
  width: 8px;
}

.popup-container::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 4px;
}

.popup-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 4px;
}
