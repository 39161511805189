/* dark-theme.css */

body {
  background-color: #282828;
  color: #ffffff;
}

.card-container {
  background-color: #333;
  border: 1px solid #555;
}
